import React from 'react';
import FlatLogo from '../../assets/images/SuddenNorth Logo/flatlogo.png';

const SuddenNorth = () => {
	return (
		<div className='SuddenNorth'>
			<div className='SNlogoDiv'>
				<p>Designed and Created by</p>
				<a
					href='https://www.suddennorth.com'
					rel='noopener noreferrer'
					target='_blank'
				>
					<img
						src={FlatLogo}
						alt='Sudden North Web Developers logo at www.suddennorth.com'
					/>
				</a>
			</div>
		</div>
	);
};

export default SuddenNorth;
